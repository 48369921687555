export const DRIVER_LOG_TO_CONSOLE = false;
export const CLIENT_METRICS_ENDPOINT = "/jwt/client_metrics";
export const SITE = "FondueReporting";
export const SERVICE_NAME = "FondueTelemetry";
export const BATCH_TIMEOUT_DURATION = 25000;

// Metric names
export const LATENCY = "Latency";
export const DWELL_TIME = "DwellTime";
export const REPORT_ITEM_EDIT_TIME = "ReportItemEditTime";

// Page names
export const REPORTS_PAGE = "Reports"
export const CREATE_REPORT_PAGE = "CreateReport"
export const RUN_HISTORY_PAGE = "RunHistory"
export const EDIT_REPORT_PAGE = "EditReport"
export const RUN_REPORT_PAGE = "RunReport"
export const REPORT_ITEMS_PAGE = "ReportItems";
export const INVALID_PAGE = "InvalidPage";

// Functionality groups
export const QUERY_FILTER = "QueryFilter";
export const OUTPUT_COLUMNS = "OutputColumns";
export const DER_ITEM_TYPE = "DERItemType";
export const WBR_ITEM_TYPE = "WBRItemType";
export const MANAGED_POLICIES_OPTION = "ManagedPoliciesOption";
export const QUERY_REGEX = "QueryRegex";
export const ADDITIONAL_ITEM_CALCULATIONS = "AdditionalItemCalculations";
