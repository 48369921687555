import React, { useState } from 'react';
import { AutosuggestProps, Input, FormField,RadioGroup, Checkbox, PropertyFilter, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { PropertyFilterProps, Query } from '@amzn/awsui-components-react/polaris/property-filter/interfaces';
import { PropertyFilterOperator, PropertyFilterToken } from '@amzn/awsui-collection-hooks';
import { FilterInfo, FilterInfoOperators, PolicyFilterProps, PolicyFilterTypes } from '.';
import { sendUseFunctionality } from "../../../../util/client_metrics/MetricUtils";
import { QUERY_REGEX } from "../../../../util/client_metrics/Constants";



export interface DerFilterFormProps {
    field: PolicyFilterTypes,
    filters: string,
    onUpdate: (value: string) => void
} 

const i18nSettings = {
    filteringAriaLabel: "your choice",
    dismissAriaLabel: "Dismiss",
    filteringPlaceholder:
        "Filter by value",
    groupValuesText: "Values",
    groupPropertiesText: "Properties",
    operatorsText: "Operators",
    operationAndText: "and",
    operationOrText: "or",
    operatorLessText: "Less than",
    operatorLessOrEqualText: "Less than or equal",
    operatorGreaterText: "Greater than",
    operatorGreaterOrEqualText:
        "Greater than or equal",
    operatorContainsText: "Like",
    operatorDoesNotContainText: "Is not Like",
    operatorEqualsText: "Equals",
    operatorDoesNotEqualText: "Does not equal",
    editTokenHeader: "Edit filter",
    propertyText: "Property",
    operatorText: "Operator",
    valueText: "Value",
    cancelActionText: "Cancel",
    applyActionText: "Apply",
    allPropertiesLabel: "All properties",
    tokenLimitShowMore: "Show more",
    tokenLimitShowFewer: "Show fewer",
    clearFiltersText: "Clear filters",
    removeTokenButtonAriaLabel: token =>
        `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
    enteredTextLabel: text => `Use: "${text}"`
};

const Operators = [
    ["=", "eq"],
    ["!=", "noteq"],
    [":", "like"],
    ["!:", "notlike"]
] as [PropertyFilterOperator, FilterInfoOperators][];

const regexTag = 'regex'
const likeTag = 'SQL like'

// "=" -> "eq"
const OperatorMap = new Map<PropertyFilterOperator, FilterInfoOperators>(Operators);

// "eq" -> "="
const ReverseOperatorMap = new Map<FilterInfoOperators, PropertyFilterOperator>(Operators.map(x => [x[1], x[0]]));



function likeOperatorFormFunction(value, onChange, field) {
        var startValue = value ? value : "";
        var startRadioValue = likeTag;
        if(startValue.startsWith(`(${regexTag})`)){
            startValue = value.substring(regexTag.length+2);
            startRadioValue = regexTag;
        }
        else if(startValue.startsWith(`(${likeTag})`)){
            startValue = value.substring(likeTag.length+2);
        }
        const [textValue, setTextValue] = useState(startValue);
        const [radioValue, setRadioValue] = useState(startRadioValue);
        const likePlaceholder = "s3:get%"
        const regexPlaceholder = "s3:(A-Z)(a-z)*"

        const [placeholderValue, setPlaceholderValue] = useState(likePlaceholder);
        return (
          <FormField id={`${field}-form-field`}>
              <RadioGroup
                  id={`${field}-form-radio-group`}
                  onChange={({ detail }) => {
                    setRadioValue(detail.value);
                    onChange("("+detail.value + ")" +textValue);
                    if(detail.value == 'regex'){
                        setPlaceholderValue(regexPlaceholder)
                    }
                    else{
                        setPlaceholderValue(likePlaceholder)
                    }
                  }}
                  value={radioValue}
                  items={[
                    { value: likeTag, label: "SQL LIKE query", description: 'The behavior is to match with % as a wildcard.' },
                    { value: regexTag, label: "REGEX match query",
                      description: <TextContent>
                        <small>The behavior is match on a regex string. Fondue uses the Java pattern syntax for regex. For more information <a target="_blank" rel="noopener noreferrer" href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/Regex/">see here</a></small>
                        <small>Regex filters are always case sensitive</small>
                      </TextContent>
                    }
                  ]}
              />
            <Input
              id={`${field}-form-input`}
              onChange={({ detail }) => {
                setTextValue(detail.value);
                onChange("("+radioValue + ")" +detail.value)}
              }
              value={textValue}
              placeholder={placeholderValue}
            />
          </FormField>
        );
}

const filteringProperties: {[key in PolicyFilterTypes]: PropertyFilterProps.FilteringProperty} = {
    "action": {
        key: "action",
        operators: [
            {
                operator: ":",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"action")}
            },
            {
                operator: "!:",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"action")}
            },
            "=",
            "!="
        ],
        propertyLabel: "Action",
        groupValuesLabel: "Action values"
    },
    "resource": {
        key: "resource",
        operators: [
            {
                operator: ":",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"resource")}
            },
            {
                operator: "!:",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"resource")}
            },
            "=",
            "!="
        ],
        propertyLabel: "Resource",
        groupValuesLabel: "Resource values"
    },
    "condition": {
        key: "condition",
        operators: [
            {
                operator: ":",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"condition")}
            },
            {
                operator: "!:",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"condition")}
            },
            "=",
            "!="
        ],
        propertyLabel: "Condition",
        groupValuesLabel: "Condition values"
    },
    "account": {
        key: "account",
        operators: [
            {
                operator: ":",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"account")}
            },
            {
                operator: "!:",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"account")}
            },
            "=",
            "!="
        ],
        propertyLabel: "Account",
        groupValuesLabel: "Account values"
    },
    "organization_id": {
        key: "organization_id",
        operators: [
            {
                operator: ":",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"organization_id")}
            },
            {
                operator: "!:",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"organization_id")}
            },
            "=",
            "!="
        ],
        propertyLabel: "OrganizationId",
        groupValuesLabel: "OrganizationId values"
    },
    "principal": {
        key: "principal",
        operators: [
            {
                operator: ":",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"principal")}
            },
            {
                operator: "!:",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"principal")}
            },
            "=",
            "!="
        ],
        propertyLabel: "Policy Principal",
        groupValuesLabel: "Policy Principal values"
    },
    "service_principal": {
        key: "service_principal",
        operators: [
            {
                operator: ":",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"service_principal")}
            },
            {
                operator: "!:",
                form: ({ value, onChange }) => {return likeOperatorFormFunction(value,onChange,"service_principal")}
            },
            "=",
            "!="
        ],
        propertyLabel: "Service Principal",
        groupValuesLabel: "Service Principal values"
    }
}

export default function DerFilterForm({field, filters, ...props }: DerFilterFormProps): JSX.Element {
    const [tokens, _caseSensitive] = buildTokensFromFilters(filters);
    const [query, setQuery] = useState<Query>(tokens);   
    const [caseSensitive, setCaseSensitive] = useState<boolean>(_caseSensitive);
    const [filteringStatusType, setFilteringStatusType] = useState<PropertyFilterProps['filteringStatusType']>("pending");
    const [filteringErrorText, setFilteringErrorText] = useState<string>("Failed");
    const [numTokens, setNumTokens] = useState<number>(0);

    function updateQuery(detail: PropertyFilterProps.Query): void {
        var isValid = true;
        var problematicValue = ""
        detail.tokens.forEach( token => {
            var val = token.value
            if(val && val.startsWith(`(${regexTag})`)){
                const regVal = val.substring(regexTag.length+2);
                try {
                    new RegExp(regVal);
                } catch(e) {
                    isValid = false;
                    problematicValue = regVal
                }
            }
        })
        if(isValid){
            // If the query is valid and the number of tokens increased, emit metric for the recently added token operator
            if (detail.tokens.length > numTokens) {
                const lastOperator = OperatorMap.get(detail.tokens[detail.tokens.length-1].operator);
                sendUseFunctionality(lastOperator, QUERY_REGEX, true);
            }
            setNumTokens(detail.tokens.length);
            setFilteringStatusType('pending')
            setQuery(detail);
            props.onUpdate(buildFiltersFromTokens(detail, caseSensitive));
        }
        else{
            setFilteringStatusType('error')
            setFilteringErrorText(`Invalid regex string :"${problematicValue}"`)
        }
    }

    function buildTokensFromFilters(filters: string): [PropertyFilterProps.Query, boolean] {
        try {
            const policyFilter = JSON.parse(filters) as PolicyFilterProps;
            return [{
                operation: policyFilter.operation,
                tokens: policyFilter.filters.map((f) => {
                    var op = ReverseOperatorMap.get(f.operator)!;
                    var val = f.value;
                    if(f.operator == regexTag){
                        op = ':';
                        val = `(${regexTag})${val}`;
                    }
                    else if(op ==':'){
                        val = `(${likeTag})${val}`;
                    }
                    if(f.operator == 'notregex'){
                        op = '!:';
                        val = `(${regexTag})${val}`;
                    }
                    else if(op =='!:'){
                        val = `(${likeTag})${val}`;
                    }
                    return {propertyKey: f.property, operator: op, value: val};
                })
            }, policyFilter.caseSensitive]
        }
        catch {
            // Support previous version values
            // TODO: Deprecate
            const filterList = filters.split("\n");
    
            // Handle empty/initial case
            if (filterList.length == 1 && filterList[0] == "") {
                return [{
                    tokens: [],
                    operation: "or"
                }, false];
            }
    
            return [{tokens: filterList.map((f) => {
                                return {propertyKey: field, operator: ":", value: f};  
                            }),
                    operation: "or"}, false];
        }
    }

    function buildFiltersFromTokens(query: PropertyFilterProps.Query, caseSensitive: boolean): string {
        const filters = query.tokens.map<FilterInfo>((t) => {
            var operator = OperatorMap.get(t.operator);
            var val = t.value;
            if(operator == 'like'){ // Make this expandable to more operators as needed later
                if(t.value.startsWith(`(${regexTag})`)){
                    operator = regexTag;
                    val=t.value.substring(regexTag.length+2); //Cut off regex  tag and the parenthesis
                }
                else if(t.value.startsWith(`(${likeTag})`)){
                    val=t.value.substring(likeTag.length+2);
                }
            }
            if(operator == 'notlike'){
                if(t.value.startsWith(`(${regexTag})`)){
                    operator = 'notregex';
                    val=t.value.substring(regexTag.length+2); //Cut off regex tag and the parenthesis
                }
                else if(t.value.startsWith(`(${likeTag})`)){
                    val=t.value.substring(likeTag.length+2);
                }
            }
            return {
                value: val,
                property: t.propertyKey,
                operator: operator
            } as FilterInfo;
        });

        const result: PolicyFilterProps = {
            caseSensitive: caseSensitive,
            operation: query.operation,
            filters: filters
        };
        return JSON.stringify(result);
    }

    function updateCaseSensitive(checked: boolean): void {
        setCaseSensitive(checked);
        props.onUpdate(buildFiltersFromTokens(query, checked));
    }

    return (
        <SpaceBetween size="xs" direction="horizontal">
            <PropertyFilter
                id={field + "-filter"}
                query={query}
                onChange={({detail}) => {updateQuery(detail);}}
                expandToViewport
                filteringProperties={[
                    filteringProperties[field]
                ]}
                disableFreeTextFiltering
                i18nStrings={i18nSettings}
                filteringConstraintText={
                  <FormField
                    errorText={filteringStatusType == 'error' ? filteringErrorText : ""}
                  />
                }
            />
            <Checkbox id={field + "-case-sensitive"} checked={caseSensitive} onChange={({detail}) => updateCaseSensitive(detail.checked)}>
                Case Sensitive
            </Checkbox>
        </SpaceBetween>
    );
}