import MetricsDriver from "./MetricsDriver";
import {MetricType} from "@amzn/katal-monitoring";
import {
    CREATE_REPORT_PAGE,
    DWELL_TIME,
    EDIT_REPORT_PAGE,
    INVALID_PAGE,
    LATENCY,
    REPORT_ITEM_EDIT_TIME,
    REPORT_ITEMS_PAGE,
    REPORTS_PAGE,
    RUN_HISTORY_PAGE,
    RUN_REPORT_PAGE,
} from "./Constants";


// export class PageStates {
//     private static instance: PageStates;
//     previousPagePath: string = "";
//     previousPageLoadTime: number = 0;
//     currentPageNavigationTime: number = 0;
//     itemEditStartTimes: Map<string, number> = new Map();
//
//     constructor() {
//         if (!PageStates.instance) {
//             PageStates.instance = this;
//             this.previousPagePath = "App";
//             this.previousPageLoadTime = 0;
//             this.currentPageNavigationTime = 0;
//             this.itemEditStartTimes = new Map();
//         }
//         return PageStates.instance;
//     }
//
//     recordPageLoad(pageName: string, timestamp: number) {
//         const latency = timestamp - this.currentPageNavigationTime;
//         console.log(`${pageName} latency: ${latency}`)
//         sendPageLatency(latency, pageName);
//         this.previousPageLoadTime = timestamp;
//     }
//
//     recordPageNavigation(pagePath: string, timestamp: number) {
//         this.currentPageNavigationTime = timestamp;
//         const dwellTime = timestamp - this.previousPageLoadTime;
//         const previousPageName = pathToName(this.previousPagePath);
//         console.log(`${previousPageName} dwell time: ${dwellTime}`)
//         sendPageDwellTime(dwellTime, previousPageName);
//         this.previousPagePath = pagePath;
//     }
//
//     recordItemEditStart(item_id: string, timestamp: number, item_name: string) {
//         console.log(`${item_name} edit start`)
//         this.itemEditStartTimes.set(item_id, timestamp);
//     }
//
//     recordItemEditEnd(item_id: string, timestamp: number, item_name: string) {
//         if (this.itemEditStartTimes.has(item_id)) {
//             // const itemEditTime = timestamp - this.itemEditStartTimes.get(item_id);
//             console.log(`${item_name} edit time: `)
//             // sendReportItemEditTime(itemEditTime);
//         }
//     }
//
// }


// Get page name using page path from location.pathname
export function pathToName(pagePath: string) {
    if (pagePath === "/reports") {
        return REPORTS_PAGE;
    }

    if (pagePath === "/reports/create") {
        return CREATE_REPORT_PAGE;
    }

    // Edit Report page has path "/reports/:report_id/edit"
    const editReport = new RegExp("/reports/.+/edit$");
    if (editReport.test(pagePath)) {
        return EDIT_REPORT_PAGE;
    }

    // Run History page has path "/reports/:report_id/result"
    const runHistory = new RegExp("/reports/.+/result$");
    if (runHistory.test(pagePath)) {
        return RUN_HISTORY_PAGE;
    }

    // Run Report page has path "/reports/:report_id/runreport"
    const runReport = new RegExp("/reports/.+/runreport$");
    if (runReport.test(pagePath)) {
        return RUN_REPORT_PAGE;
    }

    // Report Items page has path "/reports/:report_id"
    const reportItems = new RegExp("/reports/[^/]+$");
    if (reportItems.test(pagePath)) {
        return REPORT_ITEMS_PAGE;
    }

    return INVALID_PAGE;
}


// Helper functions for sending metrics to MetricsDriver

export function sendPageLatency(latency: number, pageName: string) {
    new MetricsDriver().publishMetric(LATENCY, pageName, MetricType.TIMER, latency);
}

export function sendReportItemEditTime(editTime: number) {
    new MetricsDriver().publishMetric(REPORT_ITEM_EDIT_TIME, REPORT_ITEMS_PAGE, MetricType.TIMER, editTime);
}

export function sendPageDwellTime(dwellTime: number, pageName: string) {
    new MetricsDriver().publishMetric(DWELL_TIME, pageName, MetricType.TIMER, dwellTime);
}

export function sendUseFunctionality(functionality: string | undefined, pageName: string, send: boolean) {
    if (functionality !== undefined && send) {
        new MetricsDriver().publishMetric(functionality, pageName, MetricType.COUNTER, 1);
    }
}
