import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import { getAppSetting } from "../../config/AppSettings";
import { KatalMonitoringDriver, KatalMonitoringDriverOptions } from '@amzn/katal-monitoring-aws-driver';
import { MetricType } from "@amzn/katal-monitoring";
import { DRIVER_LOG_TO_CONSOLE, CLIENT_METRICS_ENDPOINT, SITE, SERVICE_NAME, BATCH_TIMEOUT_DURATION } from "./Constants";

class MetricsDriver {
    private static instance: MetricsDriver;
    private driver: KatalMonitoringDriver | null = null;
    private midwayToken: string | null = null;
    private driverConfigs: KatalMonitoringDriverOptions | null = null;

    // Create a single instance and reuse instance on all subsequent calls
    constructor(driverConfigs: KatalMonitoringDriverOptions | null = null) {

        // Allow injecting KatalMonitoringDriverOptions
        if (driverConfigs !== null) {
            MetricsDriver.instance.driverConfigs = driverConfigs;
            if (MetricsDriver.instance.driverConfigs.url === undefined) {
                MetricsDriver.instance.driverConfigs.url = `${getAppSetting('apiUrl')}${CLIENT_METRICS_ENDPOINT}`;
            }
            // Reset driver instance so a new one can be created with new configs
            MetricsDriver.instance.driver = null;
        }

        if (!MetricsDriver.instance) {
            MetricsDriver.instance = this;

            // If no driverConfigs defined, use default values
            if(driverConfigs === null) {
                this.driverConfigs = {
                    url: `${getAppSetting('apiUrl')}${CLIENT_METRICS_ENDPOINT}`,
                    logToConsole: DRIVER_LOG_TO_CONSOLE,
                    batchTimeoutDuration: BATCH_TIMEOUT_DURATION
                }
            }
        }
        return MetricsDriver.instance;
    }

    createDriver(driverConfigs: KatalMonitoringDriverOptions) {
        return new KatalMonitoringDriver(driverConfigs);
    }

    async getDriver() {
        const newToken = await getMidwayJwtToken();

        // In the event of a new token or non-existent driver, create a new driver instance
        if (newToken !== this.midwayToken || this.driver === null) {
            console.log("Creating a new KatalMonitoringDriver instance with new token for emitting metrics");
            this.midwayToken = newToken;
            return this.createDriver({
                ...this.driverConfigs,
                headers: {"Authorization": `Bearer ${this.midwayToken}`}
            });
        }
        return this.driver;
    }

    async publishMetric(metricName: string, pageName: string, unit: MetricType, value: number | string) {
        try {
            this.driver = await this.getDriver();
            // Call publishMetric function on the driver which sends XHRHttpRequest to the API Gateway endpoint with a MetricPayload
            this.driver.publishMetric({
                site: SITE,
                serviceName: SERVICE_NAME,
                metricKey: metricName,
                value: value,
                metricType: unit,
                timestamp: Date.now(),
                dimensions: {
                    "PageName": pageName
                },
                additionalInfo: {},
            })
        }
        catch (error) {
            console.log(`Failed to publish metric for ${metricName}`)
            console.log(error);
        }
    }
}

export default MetricsDriver;

