import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, ReportItem, FondueApi } from '../../fondue-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {Box, ColumnLayout, Container, ExpandableSection} from "@amzn/awsui-components-react/polaris";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from '@amzn/awsui-components-react/polaris/select';
import { useCheckbox } from '../hooks';
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import { States } from "../../common/States";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import ReportItemsTable from "./ReportItemsTable";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import DerReportItemForm from './custom/DerReportItemForm';
import { GetReportItemTypes, IsDerReport } from '../../common/ReportTypes';
import { CustomStateParams } from '../../common/CustomStateParams';
import RunQueryComponent from "./RunQueryComponent";
import * as constants from '../../common/constants';
import AceEditor from 'react-ace';
import {useData} from '../DataContext';
import 'brace/theme/sqlserver';
import 'brace/theme/tomorrow_night_bright';
import 'brace/mode/sql';
import { values } from 'lodash';
import {sendUseFunctionality} from "../../util/client_metrics/MetricUtils";
import {ADDITIONAL_ITEM_CALCULATIONS, WBR_ITEM_TYPE} from "../../util/client_metrics/Constants";

export interface ReportItemFormProps {
    setState: (state: States) => void;
    setCustomStateParams: (params: CustomStateParams) => void;
    report: Report;
    setRefreshTable: (refresh: boolean) => void;
    setAddDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setEditDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setAddEditInfoDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    allReportItems: ReportItem[];
}

export default function ReportItemForm({setState, setCustomStateParams, report, setRefreshTable, setAddDisabled, setEditDisabled, setAddEditInfoDisabled, allReportItems}: ReportItemFormProps) {
    Object.freeze(Object.prototype);
    const [itemName, setItemName] = useState(IsDerReport(report) ? `Item ${report.report_item_order.length + 1}` : "");
    const [itemType, setItemType] = useState("");
    const [itemQuery, setItemQuery] = useState("");
    const [itemDescription, setItemDescription] = useState("");
    const [itemFormat, setItemFormat] = useState("");

    const [itemRowNum, setItemRowNum] = useState( (allReportItems.length + 1).toString());
    const [validItemRowNum, setValidItemRowNum] = useState(true);
    // Total number of Report Items in Items Table
    const totalReportItems = allReportItems.length;


    const [disabled, setDisabled] = useState(false);
    const [disableQuery, setDisableQuery] = useState(true);
    const [disableFormat, setDisableFormat] = useState(true);
    const [disableAdditionalComponents, setDisableAdditionalComponents] = useState(true);
    const [additionalMetricItems, setAdditionalMetricItems] = useState<any>(constants.ADDITIONAL_METRIC_CALC_OPTIONS)
    const [addReportItemLoading, setAddReportItemLoading] = useState(false);
    const itemTypeOptions = GetReportItemTypes(report);
    const [selectionOption, setSelectionOption] = useState({value: "", label: ""});
    const [submitReportItemLoading, setSubmitReportItemLoading] = useState(false);
    const {darkMode} = useData();
    useEffect(() => {
        if(!IsDerReport(report)){
            setItemType("query_single_result")
            setSelectionOption({value: "query_single_result", label: "query_single_result"})
        } else {
            setSelectionOption(values(itemTypeOptions)[0]);
        }
    }, [report])
    useEffect(() => {
        // Disable query and format field for header
        if(selectionOption.value === 'header'){
            setDisableQuery(true);
            setDisableFormat(true);
            setDisableAdditionalComponents(true);
        }
        // Disable format field for query_single_result
        else{
            setDisableQuery(false);
            if(selectionOption.value! === 'query_multiple_result'){
                setDisableFormat(false);
                setDisableAdditionalComponents(true);
            }
            else{
                setDisableFormat(true);
                setDisableAdditionalComponents(false);
            }
        }
    }, [selectionOption,setSelectionOption])
      /**Returns if should allow empty sql query */
    function AllowEmptySqlQuery(): boolean {
        return IsDerReport(report); // Allow for IAM DER
    }

    function getReportItem(){
        const reportItem: ReportItem = {
            id: '',
            name: itemName,
            type: itemType,
            report_id: report.id,
            bindle: report.bindle,
            report_item_config: additionalMetricItems,
            description: itemDescription,
            query: itemQuery,
            format: itemFormat,
            metric_number: Number(itemRowNum)
        };

        return reportItem;
    }

    async function createReportItem(reportItemConfig?: {}) {
        setSubmitReportItemLoading(true);

        // If Item is of type header and query provided or name not provided, setState to invalid input
        if(itemType === 'header' && (itemQuery || itemName.trim() === "")){
            setState(States.invalid)
            return;
        }
        // If Item is of type query and query or name is not provided, setState to invalid input
        if(!AllowEmptySqlQuery() && itemType !== 'header' && (itemQuery.trim() === "" || itemName.trim() ==="")){
            setState(States.invalid)
            return;
        }

        // If Item is of type query_multiple_result and no format is provided, setState to invalid input
        if(itemType === 'query_multiple_result' && itemFormat.trim() === ""){
            setState(States.invalid)
            return;
        }

        // Check if row item number provided is in valid range
        if(!validateRowItemNumber(itemRowNum)){
            setState(States.invalid)
            setValidItemRowNum(false);
            return;
        }

        // If Valid Item Row Num passed in reset to valid state
        setValidItemRowNum(true);
        
        const FondueApi = FondueApiFactory();
        var options = {}
        var minOptionsCount = 0
        if(additionalMetricItems){
            additionalMetricItems.map(item => {
                if(item.enabled){
                    options[item.value] = 'true'
                    minOptionsCount += 1
                }
                else options[item.value] = 'false'
            })
        }
        if(minOptionsCount == 0 || Object.keys(options).length === 0){
            setSubmitReportItemLoading(false);
            setState(States.invalid)
            return 
        }
        const reportItem: ReportItem = {
            id: '',
            name: itemName,
            type: itemType,
            report_id: report.id,
            bindle: report.bindle,
            report_item_config: {
                ...reportItemConfig,
                ...options
            },
            description: itemDescription,
            metric_number: Number(itemRowNum)
        };
        // Add query andor format for query item
        if(itemType !== 'header'){
            reportItem['query'] = itemQuery;

            // Add format for multiple result type
            if(itemType === 'query_multiple_result'){
                reportItem['format'] = itemFormat;
            }
        }

        // Set State to submitting
        setState(States.submitting)
        // Set Button to disabled
        setDisabled(true);
        setAddReportItemLoading(true);
        // Calling createReportItem
        await getMidwayJwtToken();
        await FondueApi.createReportItem(reportItem)
            .then((response) => {
                // Clear out form
                clearFields();
                // Setting refreshTable to true
                setRefreshTable(true);
                // Set page to loading report
                setState(States.loading);
            })
            .catch((e)=> {
                // look for API error message includes 'Internal Server Error' or any backend generic error
                const customMessage = e?.response?.data?.message
                // if error message provided, set custom-state param error message
                setCustomStateParams({customErrorMessage: customMessage})

                // Set State to failedCreate
                setState(States.failedCreate)

                // Set Submit button to enabled
                setDisabled(false);
        });
        setAddReportItemLoading(false);
        setSubmitReportItemLoading(false);
    }

    function setMetricTypeOption(event){
        setSelectionOption(event.detail.selectedOption);
        setItemType(event.detail.selectedOption.value);
    }

    // Used to validate Item Row # provided is a Number and in valid range
    function validateRowItemNumber(rowNumber: string){
        if(rowNumber && !isNaN(Number(rowNumber))){
            if(Number(rowNumber) <= totalReportItems + 1 && Number(rowNumber) > 0){
                return true;
            }
        }
        return false;

    }

    function clearFields(){
        // Clear out form
        setItemName('');
        setItemQuery('');
        setItemType('');
        setItemDescription('');
        setItemFormat('');
        setItemRowNum((allReportItems.length + 1).toString());
        setValidItemRowNum(true);
        setSelectionOption({ "value": "", "label": "" });
        setDisableQuery(true);
        setDisableFormat(true);
        setDisabled(false);
        setAddDisabled(true);
        setEditDisabled(true);
        setAddEditInfoDisabled(false);
        setAdditionalMetricItems(constants.ADDITIONAL_METRIC_CALC_OPTIONS)
    }
    function handleAdditionaMetricCalcSelection(e, item){
        var tempCalc = additionalMetricItems.map(obj => {
            if(obj.value == item.value){
                obj.enabled = e.detail.checked
            }
            return obj
        })
        setAdditionalMetricItems(tempCalc)
    }
    return (
       IsDerReport(report)
            ?<DerReportItemForm clearFields={clearFields} createReportItem={createReportItem} disabled={disabled}
                                itemDescriptionState={[itemDescription, setItemDescription]}
                                itemNameState={[itemName, setItemName]}
                                itemQueryState={[itemQuery, setItemQuery]}
                                metricTypeState={[selectionOption, setMetricTypeOption]}
                                itemTypeOptions={itemTypeOptions}
                                submitReportItemLoading={submitReportItemLoading}
                                />
            :<Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button id="cancel" variant="normal" onClick={clearFields} disabled={disabled}>
                            {constants.CANCEL}
                        </Button>
                        <Button id="submit" variant="primary" loading={addReportItemLoading} onClick={() => createReportItem()} disabled={disabled}>
                            {constants.SUBMIT}
                        </Button>
                    </SpaceBetween>
                }
            >
                <SpaceBetween size="m" direction="vertical">
                    <ColumnLayout columns={2}>
                        <FormField
                            id='row_num'
                            label={constants.EDIT_REPORT_ITEM_METRIC_NUMBER_LABEL} stretch ={true}
                            constraintText={`Choose a valid row between 1 and ${allReportItems.length + 1}.`}
                        >
                            <Input
                                inputMode="numeric"
                                type="number"
                                value = {itemRowNum.toString()}
                                onChange={(event) =>
                                    setItemRowNum(event.detail.value)
                                }
                                invalid={!validItemRowNum}
                            />
                        </FormField>
                        <FormField
                            id='metrictype'
                            label={constants.EDIT_REPORT_ITEM_TYPE_LABEL} stretch>
                            <Select selectedOption={selectionOption}
                                    placeholder="Select metric type"
                                    options={Object.keys(itemTypeOptions).map((itemType) => { return itemTypeOptions[itemType] })}
                                    onChange={event => {
                                        setMetricTypeOption(event);
                                        sendUseFunctionality(event.type, WBR_ITEM_TYPE, true);
                                    }}
                            />
                        </FormField>
                    </ColumnLayout>
                <FormField
                    id='name'
                    stretch
                    label={constants.EDIT_REPORT_ITEM_NAME_LABEL}>
                    <Input
                        value = {itemName}
                        onChange={event =>
                            setItemName(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    id='description'
                    stretch
                    label={
                        <span>
                            {constants.EDIT_REPORT_ITEM_DESCRIPTION_LABEL} <i> - optional </i>{" "}
                        </span>
                    }
                >
                    <Textarea
                        value={itemDescription}
                        rows={3}
                        onChange={({detail}) =>
                            setItemDescription(detail.value)}
                    />
                </FormField>
                {
                    !disableQuery && <FormField
                        id='query'
                        key= 'editor'
                        stretch
                        label={
                            <span>
                                {constants.EDIT_REPORT_ITEM_SQL_QUERY_LABEL}  <i> - required for query type </i>{" "}
                            </span>
                        }
                        description= {constants.EDIT_REPORT_ITEM_SQL_QUERY_DESCRIPTION}
                    >
                        <AceEditor mode="sql" theme={darkMode ? 'tomorrow_night_bright': 'sqlserver'} width='100%' height='200px'
                                    style={{border: "1px solid #ccc"}}
                                    readOnly={disableQuery}
                                    onChange={(e) => setItemQuery(e)}
                                    value={itemQuery}
                                    fontSize={13}
                                    onLoad={editorInstance => {
                                        editorInstance.container.style.resize = "vertical";
                                        // mouseup = css resize end
                                        document.addEventListener("mouseup", e => (
                                        editorInstance.resize()
                                        ));
                                    }}
                                    setOptions={{
                                        showPrintMargin: false,
                                        highlightActiveLine: true,
                                        enableSnippets: true,
                                        wrap: true,
                                        indentedSoftWrap: false,
                                        displayIndentGuides: true
                                    }}
                        /> 
                        
                    </FormField> 
                }
                {
                    !disableFormat &&
                    <FormField
                        id='format'
                        stretch
                        label={
                            <span>
                                {constants.EDIT_REPORT_ITEM_QUERY_FORMAT} <i> - required for query_multiple_result </i>{" "}
                            </span>
                        }
                        description= {constants.EDIT_REPORT_ITEM_QUERY_FORMAT_DESCRIPTION}
                    >
                        <Input
                            disabled={disableFormat}
                            value = {itemFormat}
                            onChange={event =>
                                setItemFormat(event.detail.value)}
                            placeholder={"{account} - {count}"}
                        />
                    </FormField>
                }
                {   !disableAdditionalComponents && additionalMetricItems.length > 0 &&
                        <ExpandableSection
                            defaultExpanded
                            id = 'additionalmetriccomponents'
                            headerText = {
                                <span>
                                    {constants.EDIT_REPORT_ITEM_ADDITIONAL_ITEMS_CALCULATION_LABEL} <i> - 1 required</i>
                                </span>
                            }
                        >
                            {
                                additionalMetricItems.map(item => {
                                    return <Checkbox key={item.value}
                                        onChange={ (e) => {
                                            handleAdditionaMetricCalcSelection(e, item);
                                            sendUseFunctionality(item.value, ADDITIONAL_ITEM_CALCULATIONS, item.enabled);
                                        } }
                                        checked={item.enabled}
                                        description={item.description}
                                        >
                                        {item.label}
                                    </Checkbox>
                                })
                            }
                        </ExpandableSection>
                }
                {
                    selectionOption.value !== 'header' &&   <RunQueryComponent reportItem={getReportItem()}/>
                }
                </SpaceBetween>
            </Form>
    );
}