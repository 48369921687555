import {CREATE_REPORT_PAGE, EDIT_REPORT_PAGE, INVALID_PAGE, RUN_REPORT_PAGE} from "./Constants";
import { sendPageLatency, sendReportItemEditTime, sendPageDwellTime } from "./MetricUtils"


//
export interface PageState {
    name: string;
    navigationTime: number;
    loadTime: number;
}


// Function to handle page change
// Used for capturing user dwell time on individual pages
export function recordPageNavigation(dwellTime: number, pageName: string) {
    if (pageName && pageName !== INVALID_PAGE) {
        sendPageDwellTime(dwellTime, pageName);
    }
}


// Function to handle PerformanceMark received from PerformanceObserver
// Used for capturing page (component) load latencies and user dwell time on individual report items
export const recordComponentTimes = (entries: PerformanceObserverEntryList, pageState: PageState) => {
    entries.getEntries().forEach((entry) => {
        // Use select_item and update_item performance marking intervals to calculate time spent on individual items
        // Record individual item focus startTime in startTimes
        // if (entry.name === "select_item") {
        //     startTimes.set(entry.detail.item_id, entry.startTime);
        // }

        // On item updated mark, calculate time interval and send to KatalMonitoringDriver
        // else if (entry.name === "update_item") {
        //     if (startTimes.get(entry.detail.item_id) !== null) {
        //         const editTime = entry.startTime - startTimes.get(entry.detail.item_id);
        //         sendReportItemEditTime(editTime);
        //     }
        // }

        // Record tableUpdated, primaryButtonRendered timestamps
        // The last timestamp will be used in calculating page load latency
        if (entry.name === "tableUpdated") {
            // if (startTimes.get(entry.detail.instanceIdentifier) !== null) {
            //     const latency = entry.startTime - startTimes.get(entry.detail.instanceIdentifier);
            //     sendPageLatency(latency, entry.detail.header)
            // }
            pageState.loadTime = entry.startTime;
            const latency = entry.startTime - pageState.navigationTime;
            sendPageLatency(latency, pageState.name);
        }
        else if (entry.name === "primaryButtonRendered") {
            if (pageState.name === CREATE_REPORT_PAGE || pageState.name === RUN_REPORT_PAGE || pageState.name === EDIT_REPORT_PAGE) {
                pageState.loadTime = entry.startTime;
                const latency = entry.startTime - pageState.navigationTime;
                sendPageLatency(latency, pageState.name);
            }
        }
    })
}
